import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/layout/home'
import Cart from '@/views/layout/cart'
import Category from '@/views/layout/category'
import User from '@/views/layout/user'
import Layout from '@/views/layout'
import store from '@/store'

const Login = () => import('@/views/login')
const Myorder = () => import('@/views/myorder')
const Pay = () => import('@/views/pay')
const Prodetail = () => import('@/views/prodetail')
const Search = () => import('@/views/search')
const SearchList = () => import('@/views/search/list.vue')

Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    { path: '/login', component: Login },
    {
      path: '/',
      redirect: '/home',
      component: Layout,
      children: [
        { path: '/home', component: Home },
        { path: '/cart', component: Cart },
        { path: '/category', component: Category },
        { path: '/user', component: User }

      ]
    },
    { path: '/myorder', component: Myorder },
    { path: '/pay', component: Pay },
    // 动态路由传参，确定将来是哪个商品，路由参数中携带id
    { path: '/prodetail/:id', component: Prodetail },
    { path: '/search', component: Search },
    { path: '/searchList', component: SearchList }
  ]
})
// 所有的路由在真正被访问之前，都是先会经过全局路由守卫
// 只有全局前置守卫放行了，才能到达对应的页面
// 全局前置路由守卫
// to: 表示到哪去, 到哪里去的完整路由信息对象(路劲, 参数)
// from: 从哪里来, 从哪里来的完整路由信息对象(路劲, 参数)
// (1) newx() 表示直接放行 放行到to要去的路径
// (2) next(路径) 进行拦截， 拦截到next里面配置的路径

// 定义一个数组， 专门存放用户需要权限访问的页面
const authUrls = ['/pay', '/myorder']

router.beforeEach((to, from, next) => {
  // 判断是否为权限页面
  if (!authUrls.includes(to.path)) {
    // 非权限页面直接放行
    next()
    return
  }
  // 权限页面
  const token = store.state.user.userInfo.token
  // 如果token存在说明已登录，直接放行
  if (token) next()
  // 如果token不存在则跳转到登录页面
  else next('/login')
})
// 是权限页面
export default router
