<!-- eslint-disable vue/multi-word-component-names -->
  <template>
<div>
<!-- 二级路由出口 -->
<router-view></router-view>

    <van-tabbar route active-color="#ee0a24" inactive-color="#000">
    <van-tabbar-item to="/home" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item to="/category"  icon="qr">分类页</van-tabbar-item>
    <van-tabbar-item to="/cart" icon="shopping-cart-o">购物车</van-tabbar-item>
    <van-tabbar-item to="/user" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  neme: 'LayoutIndex',
  data () {
    return {
    }
  }
}
</script>

<style>

</style>
