<template>
  <div class="count_box">
    <button class="minus" @click="changeSub">-</button>
    <input type="text" class="inp" :value="value" @change="handlechange">
    <button class="add" @click="changeAdd">+</button>

  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    changeSub () {
      if (this.value <= 1) {
        return
      }
      this.$emit('input', this.value - 1)
    },
    changeAdd () {
      this.$emit('input', this.value + 1)
    },
    handlechange (e) {
      const num = +e.target.value
      //   判读num值是否为非法的，如果非法则值为原来的值
      if (isNaN(num) || num < 1) {
        Toast('请输入正确的数量')
        e.target.value = this.value
        return
      }
      //   判读num值是否为大于999的数，如果是将值改为999
      if (num >= 999) {
        Toast('数量不能大于999')
        // eslint-disable-next-line no-const-assign
        e.target.value = 999
        return
      }
      this.$emit('input', num)
    }
  }
}
</script>

<style lang="less" scoped>
.count_box{
    width: 110px;
    display: flex;
    .add , .minus{
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    }
    .inp{
        width: 40px;
        height: 30px;
        outline: none;
        border: none;
        margin:  0 5px;
        background-color: #efefef;
        text-align: center;
    }
}

</style>
