// 约定一个通用的键名
const INFO_KEY = 'hm_shopping_info'
const HISTORY_KEY = 'hm_search_info'

// 获取个人信息
export const getInfo = () => {
  // 如果没有值则给个默认值
  const defaultObj = { toket: '', userId: '' }
  const result = localStorage.getItem(INFO_KEY)
  //   JSON.parse将字符串 转为 对象
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setInfo = (obj) => {
  //   JSON.parse将字符串 转为 对象
  localStorage.setItem(INFO_KEY, JSON.stringify(obj))
}

// 删除个人信息
export const removerInfo = () => {
  localStorage.removeItem(INFO_KEY)
}

// 获取搜索历史
export const getHistoryList = () => {
  const result = localStorage.getItem(HISTORY_KEY)
  // 判断是否有搜素记录
  return result ? JSON.parse(result) : []
}

// 设置搜素记录
export const setHistoryList = (arr) => {
  localStorage.setItem(HISTORY_KEY, JSON.stringify(arr))
}
