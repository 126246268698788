import store from '@/store'
import axios from 'axios'
import { Toast } from 'vant'
// 创建axios实例 将来对拆功能键出来的实例，进行自定义配置
// 好处：不会污染原始的axios实例
const instance = axios.create({
  baseURL: 'https://smart-shop.itheima.net/index.php?s=/api',
  timeout: 5000
//   headers: { 'X-Custom-Header': 'foobar' }
})

// 自定义配置- 请求/响应 拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 开启loading, 禁止背景点击(节流,防止多次无效触发 )
  Toast.loading({
    message: '加载中...',
    forbidClick: true, // 禁用背景点击。
    loadingType: 'spinner', // 配置loading图标
    duration: 0 // 不会自动关闭
  })
  config.headers.platform = 'H5'
  // 只要有token, 就在请求时携带, 便于请求需要授权的接口
  const token = store.getters.token
  if (token) {
    config.headers['Access-Token'] = token
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const res = response.data
  if (res.status !== 200) {
    // 提示错误
    Toast(res.message)
    // 抛出一个错误的promis
    return Promise.reject(res.message)
  } else {
    // 正确情况,直接走业务逻辑,清除loading效果
    // Toast 默认是单例模式, 后面的Toast调用了,会覆盖前面的Toats效果
    // 同时只能存在一个Toats
    Toast.clear()
  }

  return res// 后台返回的结果
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

// 导出配置好的实例
export default instance
