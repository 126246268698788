<template>
  <div id="app">
    <!--一路由出口 -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {

}

</script>

<style lang="less">

</style>
